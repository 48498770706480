.stats-choice-screen {
    padding: 20px;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
}

h2, h3 {
    text-align: center;
}

.selection-box {
    width: 100%;
    max-width: 280px; /* Limit width for mobile */
    height: 60px;
    border: 2px solid #ccc;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #555;
    background-color: #f9f9f9;
    transition: all 0.3s ease;
}

.selection-box.filled {
    border-color: #259F00;
    background-color: #E6FFE6;
    color: #259F00;
}

.rank-circle {
    position: absolute;
    left: -40px;
    width: 30px;
    height: 30px;
    background-color: #259F00;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 14px;
    border: 2px solid #ccc;
}

.ranked-selection-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.buttons {
    text-align: center;
    margin-top: 20px;
    font-family: Roboto;
    font-weight: bold;
}

button {
    padding: 16px 32px;
    font-size: 22px;
    margin: 15px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .stats-choice-screen {
        padding: 10px;
    }

    .selection-box {
        width: 90%; /* Full-width on mobile */
    }

    .ranked-selection-container {
        margin-bottom: 15px;
    }

    h2, h3 {
        font-size: 18px; /* Smaller font sizes for smaller screens */
    }

    button {
        font-size: 20px;
        padding: 16px 20px;
    }
}

@media (max-width: 480px) {
    .stats-choice-screen {
        padding: 5px;
    }

    .selection-box {
        width: 100%;
        height: 50px; /* Smaller height on very small screens */
    }

    h2, h3 {
        font-size: 16px; /* Further reduce font size */
    }

    button {
        font-size: 18px;
        padding: 14px 18px;
    }
}